import { useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { MdOutlineCall, MdOutlineMail } from "react-icons/md";
import { FiSearch } from "react-icons/fi";
import { BsCart3 } from "react-icons/bs";
import { FiMenu } from "react-icons/fi";

import { IoIosClose, IoMdClose } from "react-icons/io";
import { useSelector } from "react-redux";
import { AiOutlineHeart } from "react-icons/ai";
import { useEffect } from "react";
import axios from "axios";
import Search from "./SearchField";

const Navbar = ({ handleMenu }) => {
  const [searchItem, setSearchItem] = useState("");
  const [toggle, setToggle] = useState(false);
  const inputField = useRef(null);

  const [searchData, setSearchData] = useState([]);
  const [word, setWord] = useState("");
  const [filterData, setFilterData] = useState([]);

  const { items: data } = useSelector((state) => state.category);
  const { cartItems: product } = useSelector((state) => state.cart);

  const { items: logo } = useSelector((state) => state.logo);
  console.log(logo);
  const navActive = ({ isActive }) => {
    return {
      color: isActive ? "#083344" : null,
      Font: isActive ? "font-bold" : null,
    };
  };

  useEffect(() => {
    const fetchSearchData = async () => {
      const res = await axios.get(`${process.env.REACT_APP_URL}/api-products`);
      return setSearchData(res.data);
    };
    fetchSearchData();
  }, []);

  const handleChange = (e) => {
    const searchWord = e.target.value;
    setWord(searchWord);
    const newFilterData = searchData.filter((value) =>
      value.name.toLowerCase().includes(searchWord.toLowerCase())
    );

    if (searchWord === "") {
      setFilterData([]);
    } else {
      setFilterData(newFilterData);
    }
  };

  const handleClose2 = () => {
    setWord();
    setFilterData([]);
  };

  const handleSearch = (e) => {
    e.preventDefault();

    //form reset
    setSearchItem("");
    inputField.current.blur();
  };

  const handleClose3 = () => {
    setWord();
    setFilterData([]);
  };

  return (
    <>
      <div className="py-2 px-4  md:px-0 hidden md:block  font-normal text-sm border-b-[2px] bg-white">
        {/* upper nav */}
        <div className="wrapper upper-nav   flex items-center justify-between  ">
          <div className="upper-nav-right flex items-center justify-between gap-10">
            <a
              href="mailto:info@sombhaar.com"
              className="inline-block text-sm font-light text-slat NavLink"
            >
              <div className="flex gap-2 items-center">
                <span>
                  <MdOutlineMail className="text-lg" />
                </span>
                <span> info@sombhaar.com</span>
              </div>
            </a>
          </div>
          <div className="flex gap-5">
            <div className="flex gap-1 items-center ">
              <MdOutlineCall />
              <div className="flex gap-1">
                <p> Helpline:</p>
                <a
                  href="tel://+8801878206101"
                  className=" inline-block text-sm font-light"
                >
                  +8801729000258
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sticky top-0 lg:-top-[6.5rem] left-0 right-0 z-[9997]">
        <div className="relative px-4 md:px-0 top-0 left-0  right-0  bg-white">
          <div className="bg-white/75 py-5">
            <div className="wrapper flex gap-3 items-center justify-between">
              <div className="flex md:block  items-center justify-between gap-5  w-full md:w-auto">
                <div>
                  <button className="md:hidden block duration-700 text-[#331b08]">
                    <FiMenu onClick={handleMenu} className="text-[28px]  " />
                  </button>
                </div>
                <div>
                  <Link
                    to={"/"}
                    className="brand inline-block text-2xl italic font-semibold rounded-md overflow-hidden col-span-2"
                  >
                    <img
                      src={`${process.env.REACT_APP_URL}/backend/assets/jpg/${logo?.avatar}`}
                      className="w-[8rem]"
                      alt=""
                    />
                  </Link>
                </div>

                <div className="bg-white block md:hidden  p-4 shadow-lg">
                  <Link to="/addtocart" className=" relative group">
                    <BsCart3 />
                    <span className=" absolute bottom-2 text-sm left-2 bg-[#C3161C] h-5 w-5 flex items-center justify-center rounded-full text-white group-hover:bg-sky-500 duration-300">
                      {product.length}
                    </span>
                  </Link>
                </div>
              </div>

              {/* search button */}
              <div className="hidden md:block col-span-4">
                {/* <form
                onSubmit={handleSearch}
                className="relative flex flex-col items-center justify-center"
              >
                <Search />
              </form> */}

                <form
                  onSubmit={handleSearch}
                  className="absolute top-5 left-1/2 transform -translate-x-1/2 "
                >
                  <div className="bg-gray-400/10   border   rounded-md overflow-hidden">
                    <div className="search-container flex flex-col    md:w-[20rem] lg:w-[30rem] items-center justify-center">
                      <div className="flex flex-col items-center gap-2 w-full relative z-[9999]">
                        <input
                          onChange={handleChange}
                          type="text"
                          placeholder="Search Items......"
                          className=" bg-transparent border p-3 px-8  w-full rounded-md focus:outline-none "
                        />
                        {filterData.length === 0 ? (
                          <button className="absolute bg-[#C3161C] rounded-br-md rounded-tr-md  font-extrabold text-white py-2 px-4 top-0 right-0 bottom-0">
                            <FiSearch />
                          </button>
                        ) : (
                          <IoIosClose
                            onClick={handleClose3}
                            className="absolute right-3  text-2xl text-gray-500"
                          />
                        )}

                        {filterData.length !== 0 && (
                          <div className="search-content flex flex-col w-full z-[9999] gap-3 bg-white h-auto  p-2 overflow-hidden overflow-y-scroll">
                            {filterData.slice(0, 5).map((product) => (
                              <Link
                                onClick={handleClose3}
                                key={product.id}
                                to={`/productdetails/${product.id}`}
                                className="product-card flex items-center    gap-2"
                              >
                                <div className="product-image w-20 h-20 overflow-hidden">
                                  <img
                                    src={`${process.env.REACT_APP_URL}/uploads/product/${product.photos}`}
                                    alt=""
                                    className="w-full h-full object-cover"
                                  />
                                </div>
                                <div className="product-content text-black w-full text-sm overflow-hidden">
                                  <h4 className="font-semibold">
                                    {product?.brand_name}
                                  </h4>
                                  <p className="truncate text-lg ">
                                    {product?.name}
                                  </p>
                                  <span className="text-gray-400">
                                    {product?.unit_price}
                                  </span>
                                </div>
                              </Link>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              {/* adadawdadw */}
              <div className="hidden md:block col-span-2">
                <div className="flex gap-5 items-center justify-end">
                  <div className="bg-white  p-4 shadow-lg">
                    <Link to="/checkout" className=" relative group">
                      <BsCart3 />
                      <span className=" absolute bottom-2 text-sm left-2 bg-[#C3161C] h-5 w-5 flex items-center justify-center rounded-full text-white group-hover:bg-sky-500 duration-300">
                        {product.length}
                      </span>
                    </Link>
                  </div>
                  {/* wishlist */}
                  <div className=" bg-white  p-4 shadow-lg">
                    <AiOutlineHeart />
                  </div>
                </div>
              </div>
            </div>

            {/* search button */}
            <div className="block md:hidden">
              <form
                onSubmit={handleSearch}
                className="relative flex flex-col items-center justify-center"
              >
                <input
                  ref={inputField}
                  type="text"
                  placeholder="Search Items......"
                  onChange={handleChange}
                  className=" bg-transparent border p-3 w-full border-red-500   rounded-md focus:outline-none"
                />
                {filterData.length === 0 ? (
                  <FiSearch className="absolute right-3 text-xl text-gray-500" />
                ) : (
                  <IoIosClose
                    onClick={handleClose3}
                    className="absolute right-3  text-2xl text-gray-500"
                  />
                )}
                {filterData.length !== 0 && (
                  <div className="search-content flex flex-col w-full z-[9999] gap-3 bg-white h-auto  p-2 overflow-hidden overflow-y-scroll">
                    {filterData.slice(0, 5).map((product) => (
                      <Link
                        onClick={handleClose3}
                        key={product.id}
                        to={`/productdetails/${product.id}`}
                        className="product-card flex items-center gap-2"
                      >
                        <div className="product-image w-20 h-20 overflow-hidden">
                          <img
                            src={`${process.env.REACT_APP_URL}/uploads/product/${product.photos}`}
                            alt=""
                            className="w-full h-full object-cover"
                          />
                        </div>
                        <div className="product-content text-black w-full text-sm overflow-hidden">
                          <h4 className="font-semibold">
                            {product?.brand_name}
                          </h4>
                          <p className="truncate text-lg ">{product?.name}</p>
                          <span className="text-gray-400">
                            {product.unit_price}
                          </span>
                        </div>
                      </Link>
                    ))}
                  </div>
                )}
              </form>
            </div>
          </div>
          {/* lower nav */}
          <div className={`bg-[#C3161C] hidden md:block`}>
            <div className="container mx-auto">
              <div className="flex justify-center">
                <ul className="flex text-[0.9rem] text-violet-50  lg:text-[1rem] gap-5 py-2 justify-between items-center">
                  <li>
                    <NavLink
                      style={navActive}
                      end
                      to={"/"}
                      className="hover:text-gray-300 duration-300"
                    >
                      Home
                    </NavLink>
                  </li>
                  {data?.slice(0, 6).map((navItem) => (
                    <li key={navItem.id}>
                      <NavLink
                        className="hover:text-gray-300 duration-300"
                        style={navActive}
                        to={`/categorypage/${navItem.id}`}
                      >
                        {navItem?.name}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;

/* <div className="flex gap-1 items-center ">
<MdOutlineCall />
<p>
  Help line <a href="tel://+8801878206101">+8801878206101</a>
</p>
</div>

<Link className=" hover:underline underline-offset-2 duration-300 ">
Login
</Link>
<span className="text-slate-400/40">or</span>
<Link className=" hover:underline underline-offset-2 duration-300 ">
Registration
</Link> */
